import React from "react"
import { graphql } from "gatsby"
import AboutCityMain from "./AboutCityMain"
import ReactHtmlParser from "react-html-parser"
import Layout from "../../components/Layout"

export const query = graphql`
  query($slug: String!, $locale: String!) {
    contentfulCityModel(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      cityName
      countryFlag {
        fluid(maxWidth: 50) {
          ...GatsbyContentfulFluid
        }
      }

      cityMainDescription {
        childMarkdownRemark {
          html
        }
      }
      bannerImage {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid
        }
      }
      cityGridImages {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      aboutCityDescription {
        childMarkdownRemark {
          html
        }
      }
      aboutCityMainBullets
      aboutCitySubMainBullets
      aboutCityBulletThumbnails {
        fluid(maxWidth: 50) {
          ...GatsbyContentfulFluid
        }
      }
      popularActivitiesBullets
      popularActivitiesImagesBullets
      popularActivityImages {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      queriesSectionImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }

    allContentfulCityModel(
      filter: { slug: { ne: $slug }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          cityName
          countryFlag {
            fluid(maxWidth: 20) {
              ...GatsbyContentfulFluid
            }
          }
          cardTruncateDescription {
            cardTruncateDescription
          }
          bannerImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulTourPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          similarDestinationSubTitle
        }
      }
    }
    allContentfulDmcPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          queryDescription {
            childMarkdownRemark {
              html
            }
          }
          trustedByLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          partnersLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulLandingPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          hateContactFormTitle
          hateContactFormSubTitle
          hateContactFormLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          hateContactFormLogosId
        }
      }
    }
  }
`

const AboutCity = ({
  pageContext,
  location,
  data: {
    contentfulCityModel,
    allContentfulCityModel,
    allContentfulLandingPageModel,
    allContentfulDmcPageModel,
    allContentfulTourPageModel,
  },
}) => {
  const similarDestinations = allContentfulCityModel?.edges?.filter(
    ({ node }) => !!node?.bannerImage?.fluid
  )

  const {
    trustedByLogos,
    partnersLogos,
  } = allContentfulDmcPageModel.edges[0].node

  const {
    similarDestinationSubTitle,
  } = allContentfulTourPageModel.edges[0].node
  const {
    hateContactFormTitle,
    hateContactFormSubTitle,
    hateContactFormLogos,
    hateContactFormLogosId,
  } = allContentfulLandingPageModel.edges[0].node

  return (
    <Layout location={location} pageContext={pageContext}>
      <AboutCityMain
        similarDestinationSubTitle={similarDestinationSubTitle}
        location={location}
        cityGridImages={contentfulCityModel?.cityGridImages}
        bannerImage={contentfulCityModel?.bannerImage}
        slug={contentfulCityModel?.slug}
        cityName={contentfulCityModel?.cityName}
        countryFlag={contentfulCityModel?.countryFlag}
        cityMainDescription={
          contentfulCityModel?.cityMainDescription?.childMarkdownRemark?.html
        }
        aboutCityDescription={
          contentfulCityModel?.aboutCityDescription?.childMarkdownRemark?.html
        }
        aboutCityMainBullets={contentfulCityModel?.aboutCityMainBullets}
        aboutCitySubMainBullets={contentfulCityModel?.aboutCitySubMainBullets}
        aboutCityBulletThumbnails={
          contentfulCityModel?.aboutCityBulletThumbnails
        }
        popularActivityImages={contentfulCityModel?.popularActivityImages}
        queriesSectionImage={contentfulCityModel?.queriesSectionImage}
        similarDestinations={similarDestinations}
        trustedByLogos={trustedByLogos}
        partnersLogos={partnersLogos}
        popularActivitiesImagesBullets={
          contentfulCityModel?.popularActivitiesImagesBullets
        }
        popularActivitiesBullets={contentfulCityModel?.popularActivitiesBullets}
        hateContactFormTitle={hateContactFormTitle}
        hateContactFormSubTitle={hateContactFormSubTitle}
        hateContactFormLogos={hateContactFormLogos}
        hateContactFormLogosId={hateContactFormLogosId}
        queryDescription={ReactHtmlParser(
          allContentfulDmcPageModel?.edges[0]?.node?.queryDescription?.childMarkdownRemark?.html
            ?.split("\n")
            ?.join("<br/>")
        )}
      />
    </Layout>
  )
}

export default AboutCity
