import { withWidth, Container, Box } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CircleChecked from "@material-ui/icons/CheckCircleOutline"
import Img from "gatsby-image"
import React from "react"
import Footer from "../../components/Footer"
import { ImageSliderWithText } from "../../components/ImageSliderWithText"
import NineImages from "../../components/NineImages"
import Textfield from "../../components/TextField"
import { useSelector } from "react-redux"
import { Destinations } from "../../components/Destinations/Destinations"

const useStyles = makeStyles(theme => ({
  headerImg: {
    height: 220,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.up("md")]: {
      height: 340,
      marginTop: 0,
    },
  },
  introSection: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(12.5),
      marginBottom: theme.spacing(15),
    },
  },
  body: {
    fontSize: "1.25rem",
  },
  flag: {
    width: 20,
    height: 20,
    borderRadius: 10,
    marginBottom: theme.spacing(2),
  },
  aboutSection: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(15),
    },
  },
  bulletsWrapper: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
    },
  },
  bulletTitle: {
    fontSize: "1rem",
    lineHeight: "20px",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
  },
  activitiesHeader: {
    paddingTop: theme.spacing(15),
  },
  activitiesCircle: {
    marginRight: theme.spacing(1),
  },
  activitiesImage: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(15),
    },
  },
  image: {
    height: 500,
    width: "100%",
  },
  cardimage: {
    maxHeight: 500,
    width: 600,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: "block",
    width: "100%",
  },
}))

const AboutCity = ({
  slug,
  cityName,
  countryFlag,
  bannerImage,
  cityGridImages,
  cityMainDescription,
  aboutCityDescription,
  aboutCityMainBullets,
  aboutCitySubMainBullets,
  aboutCityBulletThumbnails,
  popularActivitiesBullets,
  popularActivityImages,
  queriesSectionImage,
  queryDescription,
  similarDestinations,
  trustedByLogos,
  partnersLogos,
  width,
  location,
  hateContactFormTitle,
  hateContactFormSubTitle,
  hateContactFormLogos,
  hateContactFormLogosId,
  popularActivitiesImagesBullets,
  similarDestinationSubTitle,
}) => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const classes = useStyles()
  const isSmall = width === "xs" || width === "sm"

  const aboutCityActualBullets = []
  if (aboutCityMainBullets?.length > 0) {
    // eslint-disable-next-line array-callback-return
    Array.from({ length: aboutCityMainBullets.length }).map((__, index) => {
      aboutCityActualBullets.push({
        title: aboutCityMainBullets[index],
        subtitle: aboutCitySubMainBullets[index],
        thumbnail: aboutCityBulletThumbnails[index],
      })
    })
  }
  return (
    <div className={locale === "zh" && "chinese-version"}>
      <Container>
        <Img className={classes.headerImg} fluid={bannerImage.fluid} />
        <Grid
          container
          spacing={3}
          justify="center"
          className={classes.introSection}
        >
          <Grid item xs={12} md={6}>
            <Img fluid={countryFlag?.fluid} className={classes.flag} />
            <Typography variant="h1">{cityName}</Typography>
            <Typography
              className={classes.body}
              dangerouslySetInnerHTML={{ __html: cityMainDescription }}
            />
          </Grid>
        </Grid>
        <NineImages images={cityGridImages} />
        {aboutCityActualBullets?.length > 0 && (
          <Grid
            container
            spacing={3}
            justify="center"
            className={classes.aboutSection}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h2">{translations?.aboutTheCity}</Typography>
              <Typography
                className={classes.body}
                dangerouslySetInnerHTML={{ __html: aboutCityDescription }}
              />
              <Grid container spacing={3} className={classes.bulletsWrapper}>
                {aboutCityActualBullets?.map(
                  ({ title, subtitle, thumbnail }) => {
                    return (
                      <Grid key={title} item md={4} xs={6}>
                        <Img
                          style={{ width: 40, height: 30, marginRight: 5 }}
                          fluid={thumbnail.fluid}
                        />
                        <Typography
                          variant="h6"
                          className={classes.bulletTitle}
                        >
                          {title}
                        </Typography>
                        <Typography>{subtitle}</Typography>
                      </Grid>
                    )
                  }
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Divider />
        {popularActivitiesBullets?.length > 0 && (
          <Grid container justify="center" className={classes.activitiesHeader}>
            <Grid item xs={12} md={6}>
              <Typography variant="h2">
                {translations?.popularActivities}
              </Typography>
              <Box display="flex" flexWrap="wrap" pt={4}>
                {popularActivitiesBullets?.map(item => (
                  <Box display="flex" key={item} pr={2} pb={2}>
                    <CircleChecked
                      fontSize="small"
                      className={classes.activitiesCircle}
                    />
                    <Typography>{item}</Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid container justify="center" className={classes.activitiesImage}>
          <Grid item xs={12} md={8}>
            <ImageSliderWithText
              data={popularActivityImages}
              style={{ marginBottom: isSmall ? 60 : 120 }}
              sliderImagesTitles={popularActivitiesImagesBullets}
            />
          </Grid>
        </Grid>
        <Divider />
      </Container>

      <Destinations
        customTitle={translations?.similarDestinations}
        locale={locale}
      />

      <Container>
        <Divider />
      </Container>

      <Textfield
        backgroundColor={"black"}
        customImage={queriesSectionImage}
        queryDescription={queryDescription}
        hateContactFormTitle={hateContactFormTitle}
        hateContactFormSubTitle={hateContactFormSubTitle}
        hateContactFormLogos={hateContactFormLogos}
        hateContactFormLogosId={hateContactFormLogosId}
        locale={locale}
      />

      <Container>
        <Divider />
      </Container>

      <Footer trustedByLogos={trustedByLogos} partnersLogos={partnersLogos} />
    </div>
  )
}

export default withWidth()(AboutCity)
